/** Implementation of the presentation of the audio player */
const playIconContainer = document.getElementById('play-icon');
const audioPlayerContainer = document.getElementById('audio-player-container');
const seekSlider = document.getElementById('seek-slider');
const volumeSlider = document.getElementById('volume-slider');
const outputContainer = document.getElementById('volume-output');
const muteIconContainer = document.getElementById('mute-icon');
var playState = 'play';
var muteState = 'unmute';


if (playIconContainer != null) {
    playIconContainer.addEventListener('click', function () {
        if (playState === 'play') {
            audio.play();
            document.getElementById('play-icon').innerHTML = "<i class=\"fas fa-pause\"></i>";
            requestAnimationFrame(whilePlaying);
            playState = 'pause';
        } else {
            audio.pause();
            document.getElementById('play-icon').innerHTML = "<i class=\"fas fa-play\"></i>";
            cancelAnimationFrame(raf);
            playState = 'play';
        }
    });
}

if (muteIconContainer != null) {
    muteIconContainer.addEventListener('click', function () {
        if (muteState === 'unmute') {
            document.getElementById('mute-icon').innerHTML = "<i class=\"fas fa-volume-mute\"></i>";
            audio.muted = true;
            muteState = 'mute';
        } else {
            document.getElementById('mute-icon').innerHTML = "<i class=\"fas fa-volume-down\"></i>";
            audio.muted = false;
            muteState = 'unmute';
        }
    });
}

function showRangeProgress(rangeInput) {
    if (rangeInput === seekSlider) audioPlayerContainer.style.setProperty('--seek-before-width', rangeInput.value / rangeInput.max * 100 + '%');
    else audioPlayerContainer.style.setProperty('--volume-before-width', rangeInput.value / rangeInput.max * 100 + '%');
}

if (seekSlider != null) {
    seekSlider.addEventListener('input', function (e) {
        showRangeProgress(e.target);
    });
}

if (volumeSlider != null) {
    volumeSlider.addEventListener('input', function (e) {
        showRangeProgress(e.target);
    });
}


/** Implementation of the functionality of the audio player */

const audio = document.querySelector('audio');
const durationContainer = document.getElementById('duration');
const currentTimeContainer = document.getElementById('current-time');
var raf = null;

function calculateTime(secs) {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    var returnedSeconds = seconds;

    if (seconds < 10) {
        returnedSeconds = '0' + seconds;
    }
    return minutes + ':' + returnedSeconds;
}

function displayDuration() {
    durationContainer.textContent = calculateTime(audio.duration);
}

function setSliderMax() {
    seekSlider.max = Math.floor(audio.duration);
}

function displayBufferedAmount() {
    const bufferedAmount = Math.floor(audio.buffered.end(audio.buffered.length - 1));
    audioPlayerContainer.style.setProperty('--buffered-width', ((bufferedAmount / seekSlider.max) * 100) + '%');
}

function whilePlaying() {
    seekSlider.value = Math.floor(audio.currentTime);
    currentTimeContainer.textContent = calculateTime(seekSlider.value);
    audioPlayerContainer.style.setProperty('--seek-before-width', (seekSlider.value / seekSlider.max * 100) + '%');
    raf = requestAnimationFrame(whilePlaying);
}


if (audio != null) {

    if (audio.readyState > 0) {
        displayDuration();
        setSliderMax();
        displayBufferedAmount();
    } else {
        audio.addEventListener('loadedmetadata', function () {
            displayDuration();
            setSliderMax();
            displayBufferedAmount();
        });
    }

    audio.addEventListener('progress', displayBufferedAmount);
}



if (seekSlider != null) {
    seekSlider.addEventListener('input', function () {
        currentTimeContainer.textContent = calculateTime(seekSlider.value);
        if (!audio.paused) {
            cancelAnimationFrame(raf);
        }
    });

    seekSlider.addEventListener('change', function () {
        audio.currentTime = seekSlider.value;
        if (!audio.paused) {
            requestAnimationFrame(whilePlaying);
        }
    });
}

if (volumeSlider != null) {
    volumeSlider.addEventListener('input', function (e) {
        const value = e.target.value;
        outputContainer.textContent = value;
        audio.volume = value / 100;
    });

    $(window).on('load', function () {
        const value = volumeSlider.value;
        outputContainer.textContent = value;
        audio.volume = value / 100;
        audio.removeAttribute('controls');
    });

    /*cosmetics*/

    $(window).on('load resize orientationchange', function () {
        $('#volume-slider').css({'width': 0, 'height': 0});
        var volume_track_height = $('.volume_control').height() - 70;
        //console.log(volume_track_height);
        $('#volume-slider').css({'width': volume_track_height + 'px', 'height': volume_track_height + 'px'});
    });
}





