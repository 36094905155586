//desktop behaviour

if ($(window).width() >= 992) {
    $('.nav-item.dropdown').on('mouseenter', function(){

        $(this).children('.dropdown-menu').stop(true,true).slideDown();
    });
    $('.nav-item.dropdown').on('mouseleave', function(){
        $(this).children('.dropdown-menu').stop(true,true).slideUp();
    });
}



/* mobile behaviour */

if ($(window).width() < 992) {
    $('.dropdown-toggle').on('click', function(){
        if (!$(this).parent().hasClass('show') ) {
            $('.dropdown-menu').slideUp();
            $(this).next('.dropdown-menu').stop(true,true).slideDown();
        }
        else {
            $(this).next('.dropdown-menu').stop(true,true).slideUp();
        }
    });

    $('.navbar-toggler').on('click', function(){

        if ($('#navbarNavDropdown').hasClass('show')) {
            $('.dropdown-menu').stop(true,true).slideUp();
        }
    });

    $('.navbar-collapse' ).swipe( {

        swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
            //console.log("You swiped " + direction );
            if (direction == 'up' && duration > 50 && duration < 250) {
                $('.dropdown-menu').slideUp();
                $('#navbarNavDropdown').collapse('toggle');
            }
        }
    });
}






