//item headlines normalize

function serviceNormalize(){
    var items = $('.service h3');
// reset the height
    items.css('min-height', '1.35rem');
// set the height
    var maxHeight = Math.max.apply(null,
        items.map(function(){
            return $(this).outerHeight()}).get()
    );
    items.css('min-height', maxHeight + 'px');
}

$(window).on('load resize orientationchange', serviceNormalize);


//slide normalize

function serviceSlideNormalize(){
    var items = $('.service .carousel-item');
    var boxes = $('.service .carousel-item .service_item_box');
// reset the height
    items.css('min-height', '0');
// set the height
    var maxHeight = Math.max.apply(null,
        items.map(function(){
            return $(this).outerHeight()}).get()
    );
    items.css('min-height', maxHeight + 'px');
    boxes.css('min-height', maxHeight - 64 + 'px');

}

$(window).on('load resize orientationchange', serviceSlideNormalize);