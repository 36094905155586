if ($('.form').length > 0) {

    var next = $('.form .btn-group.next .btn-primary');
    var back = $('.form .btn-group.previous .btn-cancel');
    var step2line = $('.form .form_status .line:nth-of-type(2)');
    var step2round = $('.form .form_status .round:nth-of-type(2)');
    var step3line = $('.form .form_status .line:nth-of-type(3)');
    var step3round = $('.form .form_status .round:nth-of-type(3)');

    if (next.attr('value') == 2) {

        $('.form .form_status .line:nth-of-type(2)').addClass('theme-background-1');
        $('.form .form_status .round:nth-of-type(2)').addClass('theme-background-1');
    }

    if (next.attr('value') == 3) {
        $('.form .form_status .line:nth-of-type(2)').addClass('theme-background-1');
        $('.form .form_status .round:nth-of-type(2)').addClass('theme-background-1');
        $('.form .form_status .line:nth-of-type(3)').addClass('theme-background-1');
        $('.form .form_status .round:nth-of-type(3)').addClass('theme-background-1');
    }

    if (next.attr('value') == 4) {
        $('.form .form_status .line:nth-of-type(2)').addClass('theme-background-1');
        $('.form .form_status .round:nth-of-type(2)').addClass('theme-background-1');
        $('.form .form_status .line:nth-of-type(3)').addClass('theme-background-1');
        $('.form .form_status .round:nth-of-type(3)').addClass('theme-background-1');
        $('.form .form_status .line:nth-of-type(4)').addClass('theme-background-1');
    }
}

