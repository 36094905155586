

$(document).ready(function() {


    //add black background

    function addBG() {
        $('nav.sticky-top').addClass('bg_black');

        if ($(window).width() >= 992) {
            $('.dropdown-menu').addClass('bg_black');
        }

        if ($(window).width() < 992){
            $('.navbar-collapse').addClass('bg_black');
        }
    }


    //remove black background

    function removeBG() {

        if ($('.navbar-collapse.show').length === 0 && $('.dropdown-menu.show').length === 0 && window.pageYOffset < 50) {

            $('nav.sticky-top').removeClass('bg_black');

            if ($(window).width() >= 992) {
                $('.dropdown-menu').removeClass('bg_black');
            }

            if ($(window).width() < 992) {
                $('.navbar-collapse').removeClass('bg_black');
            }
        }
    }


    //add black background when page is scrolled

    if (window.pageYOffset > 50) {
        addBG();
    }


    //add black background when scrolling

    $(window).scroll(function() {
        if ($(document).scrollTop() > 50) {
            addBG();

        } else {

            removeBG();
        }
    });


    //add black background when menu is used

    $('nav.sticky-top').on('mouseenter', function(){
        addBG();
    });

    if (window.pageYOffset < 50) {

        $('nav.sticky-top').on('mouseleave', function(){
            removeBG();
        });
    }

    $('navbar-toggler').on('click', function(){
        addBG();
    });



});
