$(document).ready(function() {
    setInterval(function () {

        var w;
        if ($(window).width() >= 992) {
            w = '-20%';
        }
        else if ($(window).width() < 992 && $(window).width() >= 576) {
            w = '-33.333%';
        }
        else if ($(window).width() < 576 && $(window).width() >= 400) {
            w = '-50%';
        }
        else if ($(window).width() < 400) {
            w = '-100%';
        }

        $('.reference_slider_items').animate({left: w}, 1000, function () {
            $('.reference_slider_item:first-child').detach().appendTo($('.reference_slider_items'));
            $('.reference_slider_items').css({left: 0});
        });
    }, 5000);
});

$(".carousel").swipe({

    swipe: function(event, direction, distance, duration, fingerCount, fingerData) {

        if (direction == 'left') $(this).carousel('next');
        if (direction == 'right') $(this).carousel('prev');

    },
    allowPageScroll:"vertical"

});